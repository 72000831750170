/*
 * @Author: your name
 * @Date: 2021-02-19 10:36:38
 * @LastEditTime: 2021-06-18 16:41:20
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /cep-wechat/src/main.ts
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/bass.scss';
import './assets/css/reset.css';
import './assets/css/iconfont/iconfont.css';
import '@/theme/vant.js';
import 'amfe-flexible';
import './router/routerWatch';
import VueClipboard from 'vue-clipboard2';
import './directive/index';

// import VConsole from 'vconsole';
// new VConsole();
import Storage from 'vue-ls';

const options = {
  namespace: 'weChat__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
};
Vue.use(Storage, options);
Vue.use(VueClipboard);
Vue.config.productionTip = false;



new Vue({
  router,
  store,
  render: (h: (arg0: any) => any) => h(App)
}).$mount('#app');
