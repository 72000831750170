





import wx from 'weixin-js-sdk';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class extends Vue {
  private appid = ''
}
