import Vue from 'vue';
Vue.directive('imageload', {
  // 当被绑定元素插入DOM中时
  inserted (el: any, binding, vnode) {
    console.log('inserted', el);
  },
  componentUpdated (el: any, binding) {
    console.log('componentUpdated', el);
  },
  update: (el: any, binding) => {
    console.log('update');
  },
  unbind: (el: any) => {
    el.binding = '';
  }
});