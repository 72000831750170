import Vue from 'vue';

// import 'element-ui/lib/theme-chalk/index.css'
import './vant-variables.scss';
import { Cascader, Form, AddressEdit, Popup, Field, Toast, Overlay, NavBar, Swipe, SwipeItem, Icon, Slider, ActionSheet, Cell, Picker, Area, DatetimePicker, Checkbox, CheckboxGroup, CellGroup, Loading, Button   } from 'vant';

Vue.use(Cascader);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Overlay);
Vue.use(Toast);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Icon);
Vue.use(Slider);
Vue.use(ActionSheet);
Vue.use(Cell);
Vue.use(Picker);
Vue.use(Area);
Vue.use(DatetimePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(CellGroup);
Vue.use(Loading);
Vue.use(AddressEdit);
Vue.use(Form);