import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/demo',
    name: 'demo',
    component: () => import('../demo/index.vue')
  },
  {
    path: '/dawangka',
    name: 'Dawangka',
    component: () => import('../views/dawangka.vue')
  },
  {
    path: '/fill',
    name: 'Fill',
    component: () => import('../views/fill.vue')
  },
  {
    path: '/xinkebao',
    name: 'Xinkebao',
    component: () => import('../views/xinkebao.vue')
  },
  {
    path: '/dianxinka',
    name: 'Dianxinka',
    component: () => import('../views/dianxinka.vue')
  },
  {
    path: '*',
    name: '',
    component: () => import('../views/404.vue')
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
